<template>
  <div>
    <h4>List of attached files</h4>
    <ol>
      <li
        v-for="file in storedFilesSorted"
        :key="file.meta.fullPath"
      >
        <strong>{{ file.meta.customMetadata.description }}</strong>
        <span> ({{ file.meta.customMetadata.filename }})</span>
      </li>
    </ol>
    <br class="d-print-none">
    <hr class="d-print-none">
    <div class="page-break"></div>
    <ul>
      <li
        v-for="file in storedFilesSorted"
        :key="file.meta.fullPath"
      >
        <div class="page-break"></div>
        {{ file.meta.customMetadata.filename }}
        <template v-if="file.meta.customMetadata.description">
          [{{ file.meta.customMetadata.description }}]
        </template>
        {{ file.meta.timeCreated }}
        {{ file.meta.contentType }}
        <img
          width="600"
          v-if="imageTypeCheck(file.meta.contentType)"
          :src="file.url"
          :alt="file.meta.customMetadata.description">
        <div v-else-if="file.meta.contentType==='application/pdf'">
          <FileManagerInlinePDF :file="file"/>
        </div>
        <div v-else>
          <strong>File cannot be displayed</strong>
        </div>
        <br class="d-print-none">
        <hr class="d-print-none">
      </li>
    </ul>
  </div>
</template>

<script>
import { downloadFile, getCurrentUser, getFileByApplicationId } from '@/services/firebase';
import FileManagerInlinePDF from '@/components/FileManagerInlinePDF.vue';

export default {
  name: 'FileManagerPDF',
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  components: { FileManagerInlinePDF },
  data() {
    return {
      storedFiles: [],
    };
  },
  created() {
    this.uid = getCurrentUser().uid;
    this.getStoredFiles();
  },
  computed: {
    storedFilesSorted() {
      return this.storedFiles.slice()
        .sort((a, b) => a.meta.customMetadata.order - b.meta.customMetadata.order);
    },
  },
  methods: {
    imageTypeCheck(imageType) {
      const imageTypeList = ['image/png', 'image/jpg', 'image/jpeg'];
      const isImageType = Boolean(imageTypeList.indexOf(imageType) !== -1);
      if (isImageType) {
        return true;
      }
      return false;
    },
    async getStoredFiles() {
      this.storedFiles = [];
      const filesList = await getFileByApplicationId(this.applicationId);
      filesList.items.forEach((file) => {
        file.getMetadata()
          .then((meta) => {
            if (meta.customMetadata.status === 'active' && meta.customMetadata.print === 'yes') {
              downloadFile(meta.fullPath)
                .getDownloadURL()
                .then((url) => {
                  this.storedFiles.push({
                    file,
                    meta,
                    url,
                  });
                });
            }
          });
      });
    },
  },
};
</script>

<style scoped>
@media print {
  .page-break {
    page-break-before: always
  }
}
</style>
