var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.numPages, function (pageNum) {
    return _c('pdf', {
      key: pageNum,
      attrs: {
        "src": _vm.pdfdata,
        "id": pageNum,
        "page": pageNum
      }
    }, [_c('template', {
      slot: "loading"
    }, [_vm._v(" loading content here... ")])], 2);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }