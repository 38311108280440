var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v("List of attached files")]), _c('ol', _vm._l(_vm.storedFilesSorted, function (file) {
    return _c('li', {
      key: file.meta.fullPath
    }, [_c('strong', [_vm._v(_vm._s(file.meta.customMetadata.description))]), _c('span', [_vm._v(" (" + _vm._s(file.meta.customMetadata.filename) + ")")])]);
  }), 0), _c('br', {
    staticClass: "d-print-none"
  }), _c('hr', {
    staticClass: "d-print-none"
  }), _c('div', {
    staticClass: "page-break"
  }), _c('ul', _vm._l(_vm.storedFilesSorted, function (file) {
    return _c('li', {
      key: file.meta.fullPath
    }, [_c('div', {
      staticClass: "page-break"
    }), _vm._v(" " + _vm._s(file.meta.customMetadata.filename) + " "), file.meta.customMetadata.description ? [_vm._v(" [" + _vm._s(file.meta.customMetadata.description) + "] ")] : _vm._e(), _vm._v(" " + _vm._s(file.meta.timeCreated) + " " + _vm._s(file.meta.contentType) + " "), _vm.imageTypeCheck(file.meta.contentType) ? _c('img', {
      attrs: {
        "width": "600",
        "src": file.url,
        "alt": file.meta.customMetadata.description
      }
    }) : file.meta.contentType === 'application/pdf' ? _c('div', [_c('FileManagerInlinePDF', {
      attrs: {
        "file": file
      }
    })], 1) : _c('div', [_c('strong', [_vm._v("File cannot be displayed")])]), _c('br', {
      staticClass: "d-print-none"
    }), _c('hr', {
      staticClass: "d-print-none"
    })], 2);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }