<template>
  <div>
    <pdf
      :src="pdfdata"
      v-for="pageNum in numPages"
      :key="pageNum" :id="pageNum"
      :page="pageNum"
    >
      <template slot="loading">
        loading content here...
      </template>
    </pdf>
  </div>
</template>
<script>
import pdfvuer from 'pdfvuer';

export default {
  name: 'FileManagerInlinePDF',
  components: { pdf: pdfvuer },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pdfData: undefined,
      numPages: 0,
    };
  },
  mounted() {
    this.getPdf();
  },
  methods: {
    getPdf() {
      this.pdfdata = pdfvuer.createLoadingTask(this.file.url);
      this.pdfdata.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  .page-break {
    page-break-before: always
  }
}
</style>
